<template>
  <EditPolicy />
</template>

<script>
import EditPolicy from '@/components/EditPolicy';

export default {
  components: {
    EditPolicy
  }
};
</script>
